

* {
  margin: 0;
  padding: 0;
}

.main {
  width: 100%;
  height: 100vH;
}