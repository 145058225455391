@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./fonts/montserrat.ttf") format("truetype");
  }

@font-face {
  font-family: "Blackout";
  src: local("Blackout"),
   url("./fonts/blackout_midnight.ttf") format("truetype");
  }

body, html {
  overflow-x: hidden;
}

html::before {
  content: ' ';
  display: block;
  background-image: url('./images/backround.webp');
  background-position: center;
  background-size: cover;
  height: 100vh; width: 100vw;
  top: 0; bottom: 0; left: 0; right: 0;
  position: fixed;
  z-index: -10;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }

h1, h2 {
  font-family: 'Blackout', sans-serif !important;
}

img {
  max-height: 60vh;
}

.red-font {
  color: #e23638;
}