.backround-image {
    object-fit: cover;
    width: 100%;
    height: 90vh;
    max-height: 90vh;
    position: absolute;
    z-index: -1;
}

.hero-container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    object-fit: contain;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}