.outter-styling-white {
  min-height: 70vh;
}

.outter-styling-black {
  background: rgb(0, 0, 0);
  min-height: 70vh;
}

.slick-prev, .slick-next {
  width: 40px !important;
  height: 40px !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 40px !important;
}

.slick-slide {
  padding: 0 15px;
}

.slick-list {
  margin: 0 2em;
}