.navbar_custom {
    background: #000000;
  }

.logo {
    width: auto;
    height: 6vh;
    position: relative;
    z-index: 1046;
}

.text-red {
  color: #e23638;
}

.navbar-toggler {
  padding: 0 0 !important;
}

.offcanvas.offcanvas-top {
  height: 60vh !important;
}

.menu {
  text-transform: uppercase;
  font-weight: 700;
}
