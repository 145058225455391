.ad-banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: black;
    color: white;
  }
  
  .ad-text {
    animation: marquee 40s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  